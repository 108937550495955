<template>
  <div class="login-container">
    <!-- 左侧描述性区域 -->
    <div class="login-description">
      <h1>有色金属行业企业</h1>
      <p>温室气体排放核算系统</p>
    </div>

    <!-- 右侧登录框 -->
    <div class="login-box">
      <h2>登录账户</h2>
      <!-- 使用 Element Plus 的表单验证逻辑，但保持原来的输入框和按钮 -->
      <form @submit.prevent="handleLogin" ref="loginForm">
        <!-- 用户名输入框 -->
        <div class="form-group">
          <label for="username">用户名</label>
          <input type="text" v-model="loginForm.username" required placeholder="请输入用户名" />
        </div>

        <!-- 密码输入框 -->
        <div class="form-group">
          <label for="password">密码</label>
          <input type="password" v-model="loginForm.password" required placeholder="请输入密码" />
        </div>

        <!-- 验证码输入框 -->
        <div class="form-group captcha-group">
          <label for="captcha">验证码</label>
          <input type="text" v-model="userCaptcha" required placeholder="请输入验证码" />
          <span class="captcha-code" @click="generateCaptcha" :style="{ color: captchaColor }">{{ generatedCaptcha }}</span>
        </div>

        <!-- 登录按钮 -->
        <div class="form-group">
          <button type="submit">登录</button>
        </div>

        <!-- 注册提示 -->
        <p class="register-link">
          没有账号？ <router-link to="/register">立即注册</router-link>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'; // 仅使用 Element Plus 的消息提示功能
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      userCaptcha: '', // 用户输入的验证码
      generatedCaptcha: '', // 前端生成的验证码
      captchaColor: '#000', // 验证码颜色
    };
  },
  mounted() {
    this.generateCaptcha(); // 初始化时生成验证码
  },
  methods: {
    generateCaptcha() {
      // 生成6位数的验证码
      this.generatedCaptcha = Math.random().toString(36).substr(2, 4).toUpperCase();
      // 随机生成颜色
      this.captchaColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
    async handleLogin() {
      if (this.loginForm.username === '' || this.loginForm.password === '') {
        ElMessage.error('请填写用户名和密码');
        return;
      }

      // 检查验证码是否正确
      if (this.userCaptcha.toUpperCase() !== this.generatedCaptcha) {
        ElMessage.error('验证码输入不正确');
        this.generateCaptcha(); // 验证码不正确时自动刷新
        return;
      }

      try {
        // 向后端发送登录请求
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/user/login`, {
          loginname: this.loginForm.username,
          password: this.loginForm.password,
        });

        if (response.data.code === 1) {
          const token = response.data.data.token;
          const user = response.data.data.user;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('isLoggedIn', true);
          this.$router.push('/home');
          ElMessage.success('登录成功！');
        } else {
          ElMessage.error(response.data.msg);
          this.generateCaptcha(); // 请求失败时也刷新验证码
        }
      } catch (error) {
        ElMessage.error('登录失败，请稍后再试。');
        this.generateCaptcha(); // 请求出错时刷新验证码
      }
    },
  },
};
</script>

<style scoped>
/* 保持原样式 */

/* 登录页面的容器，左右布局 */
.login-container {
  display: flex;
  height: 100vh; /* 页面全屏高度 */
  background-color: #F7F7F7; /* 背景颜色 */
}

/* 左侧描述性区域 */
.login-description {
  flex: 1;
  background-image: url('@/assets/images/login-wave.png'); /* 替换为实际的背景图路径  */
  background-size: cover;
  background-position: center;
  padding: 50px;
  color: white;
}

.login-description h1 {
  font-size: 40px;  /* 加大点，让它更醒目 */
  margin-bottom: 20px;
  color: #FFFFFF;
}

.login-description p {
  font-size: 20px;
  line-height: 1.8;
  color: #FFFFFF;
}

/* 右侧登录框 */
.login-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);  /* 稍微提升阴影，让它看起来更有层次 */
}

.login-box h2 {
  font-size: 30px;
  margin-bottom: 25px;
  color: #333333;
}

.login-box label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666666;
}

.login-box input {
  width: 100%;
  padding: 12px;
  margin-bottom: 25px;
  border: 1px solid #C4C4C4;
  border-radius: 6px;
  font-size: 16px;
}

.login-box button {
  width: 100%;
  padding: 12px;
  background-color: #00B893; /* 使用品牌色 */
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 6px;  /* 圆角稍微加大，让按钮更圆润 */
  transition: background-color 0.3s;
}

.login-box button:hover {
  background-color: #FFA900; /* 悬停时使用辅助色 */
}

/* 注册链接 */
.register-link {
  margin-top: 20px;
  text-align: center;
}

.register-link a {
  color: #31bdec;
  text-decoration: none;
}

.register-link a:hover {
  color: #00B893;
}


.captcha-group {
  display: flex;
}
.captcha-group label {
  display: flex;
  align-items: center; /* 使label中的文字上下居中 */
  text-align: center;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
}

.captcha-group input {
  flex: 1;
  max-width: 180px; /* 调整输入框的宽度与验证码框保持一致 */
  margin-right: 10px;
  padding: 10px;
  height: 40px; /* 输入框高度 */
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  box-sizing: border-box; /* 确保 padding 和 width 不冲突 */
}

.captcha-code {
  display: inline-block;
  height: 40px; /* 确保验证码高度与输入框一致 */
  line-height: 40px; /* 确保验证码内容垂直居中 */
  padding: 0 15px; /* 验证码的左右内边距 */
  border: none; /* 验证码框的边框样式 */
  border-radius: 4px; /* 验证码框的圆角 */
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle; /* 确保验证码框与输入框对齐 */
  transition: all 0.3s ease;
}

.captcha-code:hover {
  transform: scale(1.05); /* 悬停时稍微放大 */
}

.captcha-label {
  margin-right: 10px; /* 调整标签与输入框的间距 */
  font-size: 16px; /* 保持字体大小一致 */
}

</style>
