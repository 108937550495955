<template>
  <div class="data-center-option">
    <div class="button-container">
      <el-button type="primary" class="add-report-button" @click="showAddDialog = true">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增填报</span>
      </el-button>
    </div>

    <!-- 新增报告对话框 -->
    <el-dialog title="新增填报" v-model="showAddDialog" width="500px">
      <el-form :model="newReport" label-width="120px" ref="reportForm">
        <el-form-item label="受核查单位" prop="companyName" :rules="[{ required: true, message: '请输入受核查单位', trigger: 'blur' }]">
          <el-input v-model="newReport.companyName" placeholder="请输入受核查单位"></el-input>
        </el-form-item>
        <el-form-item label="核查机构" prop="verificationAgency" :rules="[{ required: true, message: '请输入核查机构', trigger: 'blur' }]">
          <el-input v-model="newReport.verificationAgency" placeholder="请输入核查机构"></el-input>
        </el-form-item>
        <el-form-item label="签发状态" prop="status" :rules="[{ required: true, message: '请输入签发状态', trigger: 'blur' }]">
          <el-select v-model="newReport.status" placeholder="请选择签发状态">
            <el-option label="进行中" value="进行中"></el-option>
            <el-option label="已提交" value="已提交"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddDialog = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">添加</el-button>
      </template>
    </el-dialog>

    <!-- 表格列表 -->
    <el-table :data="paginatedData" border style="width: 100%;" stripe :fit="true">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="companyName" label="受核查单位" />
      <el-table-column prop="verificationAgency" label="核查机构" />
      <el-table-column prop="status" label="签发状态" />

      <!-- 操作列 -->
      <el-table-column label="操作" width="180">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button type="primary" size="small" @click="edit(row)">编辑</el-button>
            <el-button type="danger" size="small" @click="deleteRow(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
        style="margin-top: 20px; text-align: right;"
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        v-model:currentPage="currentPage"
        @current-change="handlePageChange"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';
import { CirclePlus } from '@element-plus/icons-vue';

export default {
  name: "ReportCenter",
  components: {
    CirclePlus,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      showAddDialog: false, // 控制弹窗显示
      newReport: {
        companyName: '',
        verificationAgency: '',
        status: '填写中' // 默认状态
      }
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/list`, {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.code === 1) {
          this.tableData = response.data.data.data;
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },

    async addReport() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/add`, this.newReport, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.code === 1) {
          ElMessage.success("报告添加成功");
          this.showAddDialog = false;  // 关闭弹窗
          this.fetchData(); // 刷新数据
          this.resetForm();
        } else {
          ElMessage.error(response.data.msg || "添加失败");
        }
      } catch (error) {
        ElMessage.error("添加请求失败");
      }
    },

    onSubmit() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.addReport();
        } else {
          ElMessage.error("请完善表单信息");
          return false;
        }
      });
    },

    resetForm() {
      this.newReport = { companyName: '', verificationAgency: '', status: '填写中' };
    },

    handlePageChange(page) {
      this.currentPage = page;
    },

    edit(row) {
      const basePath = window.location.href.split('#')[0];
      const url = `${basePath}#/edit-report/${row.id}`;
      window.open(url, '_blank');
    },

    async deleteRow(row) {
      try {
        await ElMessageBox.confirm('确定删除这条记录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/reports/delete`, { id: row.id }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.code === 1) {
          this.tableData = this.tableData.filter(item => item.id !== row.id);
          ElMessage.success('删除成功');
        } else {
          ElMessage.error(response.data.msg || '删除失败');
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除请求失败');
        }
      }
    },
  },
  mounted() {
    this.fetchData(); // 组件加载时调用 fetchData
  },
};
</script>

<style scoped>
.data-center-option {
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 0;
}

.add-report-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-report-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.el-table .el-table__header-wrapper th {
  background-color: var(--lightgray-color);
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.el-table th,
.el-table td {
  text-align: center;
  color: var(--text-color);
}

.el-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  gap: 5px;
}
</style>
