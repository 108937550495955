<template>
  <el-header class="header">
    <div class="logo">
      <span>有色金属行业企业温室气体排放核算系统</span>
    </div>
    <div class="user-info">
      <span class="user-name">您好, abidata</span>
      <el-dropdown>
        <span class="user-company">
          用户所属: 有色金属行业企业温室气体排放核算系统<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const changePassword = () => {
  console.log("修改密码功能触发");
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("isLoggedIn");
  console.log("退出登录，跳转到登录页面");
  router.push("/login"); // Redirect to the login page
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: var(--primary-color);
  color: white;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  color: var(--white-color);
}

/* 统一 user-name 和 user-company 的样式 */
.user-name,
.user-company {
  margin: 0 15px; /* 增加左右间距 */
  color: var(--white-color);
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}
</style>
