<template>
  <div class="register-container">
    <!-- 左侧注册注意事项区域 -->
    <div class="register-notice">
      <h1>注册须知</h1>
      <p>请确保您填写的信息真实有效。</p>
      <ul>
        <li>用户名不能包含特殊字符。</li>
        <li>密码长度至少为8位，包含字母和数字。</li>
        <li>确认密码必须与密码一致。</li>
        <li>验证码用于确保操作安全性。</li>
      </ul>
    </div>

    <!-- 右侧注册表单 -->
    <div class="register-box">
      <h2>注册</h2>
      <form @submit.prevent="handleRegister">
        <!-- 用户名输入框 -->
        <div class="form-group">
          <label for="username">用户名</label>
          <input type="text" v-model="registerForm.username" required placeholder="请输入用户名" />
        </div>

        <!-- 密码输入框 -->
        <div class="form-group">
          <label for="password">密码</label>
          <input type="password" v-model="registerForm.password" required placeholder="请输入密码" />
        </div>

        <!-- 确认密码输入框 -->
        <div class="form-group">
          <label for="confirmPassword">确认密码</label>
          <input type="password" v-model="registerForm.confirmPassword" required placeholder="请再次输入密码" />
        </div>

        <!-- 所属单位输入框 -->
        <div class="form-group">
          <label for="company">所属单位</label>
          <input type="text" v-model="registerForm.company" required placeholder="请输入所属单位" />
        </div>

        <!-- 图形验证码 -->
        <div class="form-group captcha-group">
          <label for="captcha">验证码</label>
          <input type="text" v-model="userCaptcha" required placeholder="请输入验证码" />
          <span class="captcha-code" @click="generateCaptcha" :style="{ color: captchaColor }">{{ generatedCaptcha }}</span>
        </div>

        <!-- 注册按钮 -->
        <div class="form-group">
          <button type="submit">注册</button>
        </div>

        <!-- 登录提示 -->
        <p class="login-link">
          已有账号？ <router-link to="/login">去登录</router-link>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
  name: 'UserRegister',
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        confirmPassword: '',
        company: '',
      },
      userCaptcha: '', // 用户输入的验证码
      generatedCaptcha: '', // 前端生成的验证码
      captchaColor: '#000', // 验证码颜色
    };
  },
  mounted() {
    this.generateCaptcha(); // 初始化时生成验证码
  },
  methods: {
    generateCaptcha() {
      // 生成6位数的验证码
      this.generatedCaptcha = Math.random().toString(36).substr(2, 4).toUpperCase();
      // 随机生成颜色
      this.captchaColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
    async handleRegister() {
      // 检查密码和确认密码是否一致
      if (this.registerForm.password !== this.registerForm.confirmPassword) {
        ElMessage.error('密码与确认密码不一致');
        return;
      }

      // 检查验证码是否正确
      if (this.userCaptcha.toUpperCase() !== this.generatedCaptcha) {
        ElMessage.error('验证码输入不正确');
        this.generateCaptcha(); // 验证码不正确时自动刷新
        return;
      }

      // 提交注册请求到后端
      try {
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/user/register`, {
          username: this.registerForm.username,
          password: this.registerForm.password,
          company: this.registerForm.company,
        });

        if (response.data.code === 1) {
          ElMessage.success('注册成功！');
          this.$router.push('/login'); // 注册成功后跳转到登录页面
        } else {
          ElMessage.error(response.data.message);
          this.generateCaptcha(); // 请求失败时刷新验证码
        }
      } catch (error) {
        ElMessage.error('注册失败，请稍后再试。');
        this.generateCaptcha(); // 请求出错时刷新验证码
      }
    },
  },
};
</script>

<style scoped>
/* 注册页面的容器，左右布局 */
.register-container {
  display: flex;
  height: 100vh;
  background-color: #F7F7F7; /* 背景颜色 */
}

/* 左侧注册注意事项区域 */
.register-notice {
  flex: 1;
  background-color: #008893; /* 使用品牌色 */
  color: white;
  padding: 50px;
}

.register-notice h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.register-notice p {
  font-size: 18px;
  color: #FFFFFF;
}

.register-notice ul {
  list-style-type: disc;
  margin-top: 20px;
}

.register-notice li {
  margin-bottom: 10px;
  font-size: 16px;
}

/* 右侧注册表单区域 */
.register-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.register-box h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333333;
}

.register-box label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666666;
}

.register-box input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  font-size: 16px;
}

.captcha-image {
  display: block;
  margin-top: 10px;
  cursor: pointer;
}

.register-box button {
  width: 100%;
  padding: 10px;
  background-color: #00B893; /* 使用品牌色 */
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.register-box button:hover {
  background-color: #FFA900; /* 悬停时使用辅助色 */
}

/* 登录链接样式 */
.login-link {
  margin-top: 20px;
  text-align: center;
}

.login-link a {
  color: #31bdec; /* 引导色 */
  text-decoration: none;
}

.login-link a:hover {
  color: #00B893; /* 悬停时品牌色 */
}

.captcha-group {
  display: flex;
}
.captcha-group label {
  display: flex;
  align-items: center; /* 使label中的文字上下居中 */
  text-align: center;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
}

.captcha-group input {
  flex: 1;
  max-width: 180px; /* 调整输入框的宽度与验证码框保持一致 */
  margin-right: 10px;
  padding: 10px;
  height: 40px; /* 输入框高度 */
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  box-sizing: border-box; /* 确保 padding 和 width 不冲突 */
}

.captcha-code {
  display: inline-block;
  height: 40px; /* 确保验证码高度与输入框一致 */
  line-height: 40px; /* 确保验证码内容垂直居中 */
  padding: 0 15px; /* 验证码的左右内边距 */
  border: none; /* 验证码框的边框样式 */
  border-radius: 4px; /* 验证码框的圆角 */
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle; /* 确保验证码框与输入框对齐 */
  transition: all 0.3s ease;
}

.captcha-code:hover {
  transform: scale(1.05); /* 悬停时稍微放大 */
}

.captcha-label {
  margin-right: 10px; /* 调整标签与输入框的间距 */
  font-size: 16px; /* 保持字体大小一致 */
}
</style>
