<template>
  <div class="data-center-option">
    <!-- 新增大类和子目录按钮 -->
    <div class="button-container">
      <el-button type="primary" @click="addCategory" size="small">新增大类</el-button>
      <el-button type="primary" @click="addSubDirectory" size="small">新增子目录</el-button>
    </div>

    <!-- 多级菜单目录 -->
    <el-tree
        ref="directoryTree"
        :data="directoryData"
        :props="defaultProps"
        :default-expanded-keys="expandedKeys"
        node-key="id"
        highlight-current
        @node-click="handleNodeClick"
        class="directory-tree"
    >
      <template #default="{ node, data }">
        <div class="tree-node">
          <span class="node-label">{{ data.label }}</span>
          <span class="leader-dots"></span>
          <div class="action-buttons">
            <el-button type="text" size="mini" @click.stop="editNode(data)">编辑</el-button>
            <el-button type="text" size="mini" @click.stop="deleteNode(data)">删除</el-button>
          </div>
        </div>
      </template>
    </el-tree>

    <!-- 新增/编辑目录对话框 -->
    <el-dialog :title="isEditing ? '编辑目录' : '新增目录'" v-model="showEditDialog" width="400px">
      <el-form :model="currentNode" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="currentNode.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showEditDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSave" size="small">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  data() {
    return {
      directoryData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showEditDialog: false,
      isEditing: false,
      currentNode: {},
      currentParentNode: null,
      expandedKeys: [] // Array to store IDs of nodes to be expanded
    };
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/default_contents/list`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data && response.data.code === 1) {
          this.directoryData = response.data.data.data;
          this.setExpandedKeys(this.directoryData); // Set expanded keys after fetching data
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },

    setExpandedKeys(data) {
      const getKeys = (nodes) => {
        let keys = [];
        nodes.forEach(node => {
          keys.push(node.id); // Add the current node ID
          if (node.children && node.children.length) {
            keys = keys.concat(getKeys(node.children)); // Recursively add children IDs
          }
        });
        return keys;
      };
      this.expandedKeys = getKeys(data);
    },

    addCategory() {
      this.currentNode = { label: '', children: [] };
      this.isEditing = false;
      this.showEditDialog = true;
      this.currentParentNode = null;
    },

    addSubDirectory() {
      if (!this.currentParentNode) {
        ElMessage.warning("请先选择一个父级目录以新增子目录");
        return;
      }
      this.currentNode = { label: '', children: [] };
      this.isEditing = false;
      this.showEditDialog = true;
    },

    handleNodeClick(node) {
      this.currentParentNode = node;
      this.$refs.directoryTree.setCurrentKey(node.id);
    },

    editNode(data) {
      this.currentNode = { ...data };
      this.isEditing = true;
      this.showEditDialog = true;
    },

    async deleteNode(data) {
      try {
        const token = localStorage.getItem('token');
        await ElMessageBox.confirm('确定删除该目录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/delete`,
            { id: data.id },
            { headers: { Authorization: `Bearer ${token}` }}
        );
        ElMessage.success('删除成功');
        this.fetchData();
      } catch (error) {
        ElMessage.error("删除请求失败");
      }
    },

    async onSave() {
      try {
        const token = localStorage.getItem('token');
        if (this.isEditing) {
          await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/edit`, {
            id: this.currentNode.id,
            label: this.currentNode.label
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          ElMessage.success("编辑成功");
        } else {
          const response = await axios.post(`${process.env.VUE_APP_HOST}/api/default_contents/add`, {
            label: this.currentNode.label,
            parent_id: this.currentParentNode ? this.currentParentNode.id : 0
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          if (response.data && response.data.code === 1) {
            this.currentNode.id = response.data.data.id;
            this.isEditing = true;
            ElMessage.success("大类创建成功");
          } else {
            ElMessage.error("创建大类失败: " + response.data.msg);
            return;
          }
        }
        this.showEditDialog = false;
        this.fetchData();
      } catch (error) {
        ElMessage.error("操作失败: " + error.message);
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.data-center-option {
  padding: 0;
  background-color: var(--background-color);
  height: 100%; /* 父容器高度设置为100% */
  overflow: auto; /* 启用滚动条 */
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.directory-tree {
  border: 1px solid var(--lightgray-color);
  padding: 10px;
  border-radius: 4px;
  overflow-y: auto; /* 目录滚动 */
  max-height: 70vh; /* 设置最大高度，避免内容超出 */
}

.el-tree-node__content {
  height: 50px !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.el-tree-node__content:hover {
  background-color: #f5f7fa;
}

.tree-node {
  display: flex;
  align-items: center;
  width: 100%;
}

.node-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
}

.leader-dots {
  flex-grow: 1;
  border-bottom: 1px dotted #333;
  margin: 0 10px;
  height: 0;
}

.action-buttons .el-button {
  font-size: 12px;
  color: #409EFF;
  margin-left: 5px;
}

.el-dialog__footer {
  text-align: right;
}

.el-form-item__label {
  font-weight: 500;
}

.el-tree-node__children {
  margin-left: 20px;
  border-left: 1px dashed var(--lightgray-color);
}
</style>
