<template>
  <div class="edit-report-details">
    <!-- 顶部操作区域 -->
    <div class="header">
      <!-- 自定义菜单栏 -->
      <div class="menu">
        <div
            v-for="(item, index) in menuItems"
            :key="index"
            :class="['menu-item', { active: activeMenu === item.name }]"
            @click="activeMenu = item.name"
        >
          {{ item.label }}
        </div>
      </div>

      <!-- 保存按钮 -->
      <el-button type="primary" class="save-button">
        <el-icon><Save /></el-icon> 保存
      </el-button>
    </div>

    <!-- 上传说明 -->
    <div class="upload-instruction">
      <p>说明：按规定定格式制作报告</p>
    </div>

    <!-- 表单区域，根据activeMenu切换不同的组件 -->
    <div class="form">
      <component :is="currentTabComponent" />
    </div>
  </div>
</template>

<script>
import { Save } from '@element-plus/icons-vue';
import FillingTab1 from '@/components/FillingCenter/FillingTab1.vue';
import FillingTab2 from '@/components/FillingCenter/FillingTab2.vue';
import FillingTab3 from '@/components/FillingCenter/FillingTab3.vue';
import FillingTab4 from '@/components/FillingCenter/FillingTab4.vue';
import FillingTab5 from '@/components/FillingCenter/FillingTab5.vue';
import FillingTab6 from '@/components/FillingCenter/FillingTab6.vue';

export default {
  name: 'EditReportDetails',
  components: { Save, FillingTab1, FillingTab2, FillingTab3, FillingTab4, FillingTab5, FillingTab6 },
  data() {
    return {
      activeMenu: 'tab1',
      menuItems: [
        { label: '排放核查结论性表格', name: 'tab1' },
        { label: '企业基本情况', name: 'tab2' },
        { label: '核查过程和方法', name: 'tab3' },
        { label: '核查发现', name: 'tab4' },
        { label: '核查结论', name: 'tab5' },
        { label: '附件', name: 'tab6' },
      ],
      form: {
        companyName: '有色金属行业企业温室气体排放核算系统',
        location: '北京市'
      }
    };
  },
  computed: {
    currentTabComponent() {
      const componentMap = {
        tab1: 'FillingTab1',
        tab2: 'FillingTab2',
        tab3: 'FillingTab3',
        tab4: 'FillingTab4',
        tab5: 'FillingTab5',
        tab6: 'FillingTab6',
      };
      return componentMap[this.activeMenu];
    }
  }
};
</script>

<style scoped>
.edit-report-details {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
}

.menu {
  display: flex;
  flex: 1;
  justify-content: center;
}

.menu-item {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu-item:hover {
  background-color: #f2f6fc;
}

.menu-item.active {
  background-color: #409eff;
  color: white;
  border-color: #409eff;
}

.save-button {
  display: flex;
  align-items: center;
  background-color: #409eff;
  color: white;
}

.upload-instruction {
  background-color: #FFF9DB;
  padding: 10px;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}

.form {
  margin-bottom: 20px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  background-color: #f5f7fa;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  text-align: center;
  color: #606266;
}

.el-upload__text {
  font-size: 16px;
  color: #409eff;
  margin-top: 10px;
}

.el-upload__tip {
  font-size: 12px;
  color: #909399;
  margin-top: 5px;
}
</style>
