<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* 全局样式 */
nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #42b983;
}
</style>
