<template>
  <div class="filling-tab-container">
    <!-- OnlyOffice Document and Placeholder Settings Section -->
    <div class="content-container">
      <div class="word-preview">
        <h3>Word 文档内容</h3>
        <div id="placeholder" class="document-content"></div>
      </div>


    </div>
  </div>
</template>

<script>
/* global DocsAPI, Asc, Api */
export default {
  name: 'FillingTab4',
  data() {
    return {
      form: {
        companyName: '',
        auditYear: '',
        totalEmissions: ''
      },
      placeholderType: "text",
      placeholderForm: {
        placeholderName: "",
      },
      docEditor: null,
      connector: null,
      textPlaceholderCount: 1,
      imagePlaceholderCount: 1,
    };
  },
  mounted() {
    this.loadOnlyOfficeAPI()
        .then(() => {
          this.initDocEditor();
        })
        .catch((error) => {
          console.error("Failed to load OnlyOffice API:", error);
        });
    this.generatePlaceholderName();
  },
  methods: {
    loadOnlyOfficeAPI() {
      return new Promise((resolve, reject) => {
        if (typeof DocsAPI !== "undefined") {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = `${process.env.VUE_APP_IP}/web-apps/apps/api/documents/api.js`;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
    initDocEditor() {
      const config = {
        documentType: "word",
        document: {
          title: "排放核查结论性表格",
          url: `${process.env.VUE_APP_HOST}/onlyoffice/4.docx`,
          fileType: "docx",
          key: "B949CB7904",
        },
        editorConfig: {
          mode: "edit",
          lang: "zh-CN",
          customization: {
            autosave: true,
            chat: false,
            about: false,
            comments: false,
            anonymous: {
              request: false, // 禁用协作名称提示框
              label: "Guest"   // 设置默认协作名称
            },
            logo: {
              image: `${process.env.VUE_APP_HOST}/img/cnxh.png`,
              imageDark: `${process.env.VUE_APP_HOST}/img/cnxh.png`,
              url: `${process.env.VUE_APP_HOST}`,
              visible: true,
            },
            features: {
              spellcheck: false       // Disable spell checking to remove red underline
            },
            feedback: {
              url: `${process.env.VUE_APP_HOST}/feedback`,
              visible: true,
            },
            hideRulers: true,
            compactHeader: true,
            layout: {
              leftMenu: false,   // Hide the left menu
              rightMenu: false,  // Hide the right menu
              toolbar: false,
            }
          },
        },
        events: {
          onDocumentReady: this.createConnector,
        },
        height: "95%",
        width: "100%",
      };

      this.docEditor = new DocsAPI.DocEditor("placeholder", config);
    },
    createConnector() {
      this.connector = this.docEditor.createConnector();
    },
    generatePlaceholderName() {
      if (this.placeholderType === "text") {
        this.placeholderForm.placeholderName = `{{text${this.textPlaceholderCount++}}}`;
      } else if (this.placeholderType === "image") {
        this.placeholderForm.placeholderName = `{{img${this.imagePlaceholderCount++}}}`;
      }
    },
    updatePlaceholderName() {
      const name = this.placeholderForm.placeholderName.replace(/[{}]/g, '');
      this.placeholderForm.placeholderName = `{{${name}}}`;
    },
    insertPlaceholder() {
      const placeholder = this.placeholderForm.placeholderName;

      if (!this.connector) {
        console.error("Connector is not initialized.");
        return;
      }

      Asc.scope.text = placeholder;
      this.connector.callCommand(function() {
        const doc = Api.GetDocument();
        const paragraph = Api.CreateParagraph();
        paragraph.AddText(Asc.scope.text);
        doc.InsertContent([paragraph]);
      }, function() {
        console.log("占位符已插入到文档中");
      });

      this.generatePlaceholderName();
    }
  },
};
</script>

<style scoped>
.filling-tab-container {
  display: flex;
  flex-direction: column;
}

.form-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  justify-content: space-between;
}

.word-preview {
  flex: 2;
  margin-right: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.placeholder-settings {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
  position: sticky;
  top: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.form-container {
  max-width: 100%;
}
.word-preview h3{
  text-align: center;
  margin:10px 0;
}
</style>
