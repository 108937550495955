import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/global.css';  // 引入全局CSS
import './assets/iconfont/iconfont.css';  // 引入 iconfont 样式文件
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css'; // 引入 Element Plus 的样式

const app = createApp(App);

app.use(ElementPlus); // 使用 Element Plus 插件
app.use(router);  // 使用路由

// 全局注册所有 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.mount('#app');  // 挂载到 id 为 #app 的 DOM 上
