<template>
  <div class="data-center-option">
    <!-- 新增大类按钮 -->
    <div class="button-container">
      <el-button type="primary" class="add-category-button" @click="addCategory">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">新增大类</span>
      </el-button>

      <!-- 创建默认目录按钮 -->
      <el-button type="primary" class="create-default-button" @click="goToDocumentDefault">
        <el-icon class="button-icon">
          <CirclePlus />
        </el-icon>
        <span class="button-text">创建默认目录</span>
      </el-button>
    </div>

    <!-- 多级菜单目录 -->
    <div class="directory-container">
      <el-tree
          ref="directoryTree"
          :data="directoryData"
          :props="defaultProps"
          :default-expanded-keys="expandedKeys"
          node-key="id"
          highlight-current
          expand-on-click-node="false"
          @node-click="handleNodeClick"
          class="directory-tree"
      >
        <!-- 自定义节点 -->
        <template #default="{ node, data }">
          <div :class="['tree-node', { 'root-node': node.level === 1 }]">
            <!-- 展开/折叠图标 -->
            <span
                class="expand-icon"
                @click.stop="toggleNode(node)"
            >
              <!-- 使用不同图标表示展开和折叠状态 -->
              <i :class="node.expanded ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </span>
            <span class="node-label">{{ data.label }}</span>
            <!-- 仅在非一级分类节点显示 leader-dots 和操作按钮 -->
            <span v-if="node.level !== 1" class="leader-dots"></span>
            <div v-if="node.level === 1" class="action-buttons">
              <el-button type="text" size="mini" @click.stop="deleteCategory(data)">删除</el-button>
            </div>
            <div v-if="node.level !== 1" class="action-buttons">
              <el-button type="text" size="mini" @click.stop="addSubDirectory(data)">新增子目录</el-button>
              <el-button type="text" size="mini" @click.stop="uploadDocument(data)">上传模板</el-button>
              <el-button type="text" size="mini" @click.stop="editNode(data)">编辑</el-button>
              <el-button type="text" size="mini" @click.stop="deleteNode(data)">删除</el-button>
            </div>
          </div>
        </template>
      </el-tree>
    </div>

    <!-- 新增大类对话框 -->
    <el-dialog title="新增大类" v-model="showAddCategoryDialog" width="400px">
      <el-form :model="newCategory" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="newCategory.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddCategoryDialog = false" size="small">取消</el-button>
        <el-button :disabled="!isCategoryCreated" type="primary" @click="importDefaultContents" size="small">引入现有目录</el-button>
        <el-button type="primary" @click="onSaveNewCategory" size="small">保存</el-button>
      </template>
    </el-dialog>

    <!-- 编辑目录对话框 -->
    <el-dialog title="编辑目录" v-model="showEditDialog" width="400px">
      <el-form :model="currentNode" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="currentNode.label" placeholder="请输入目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showEditDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSaveEditNode" size="small">保存</el-button>
      </template>
    </el-dialog>

    <!-- 新增子目录对话框 -->
    <el-dialog title="新增子目录" v-model="showAddSubDirectoryDialog" width="400px">
      <el-form :model="newSubDirectory" label-width="80px">
        <el-form-item label="目录名称">
          <el-input v-model="newSubDirectory.label" placeholder="请输入子目录名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="showAddSubDirectoryDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="onSaveNewSubDirectory" size="small">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

export default {
  data() {
    return {
      directoryData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showAddCategoryDialog: false,
      showEditDialog: false,
      showAddSubDirectoryDialog: false,
      isCategoryCreated: false, // 用于控制引入目录按钮是否可用
      newCategory: { label: '' },
      currentNode: {},
      newSubDirectory: { label: '' },
      currentParentNode: null,
      expandedKeys: [] // Array to store IDs of nodes to be expanded
    };
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/documents/list`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data && response.data.code === 1) {
          this.directoryData = response.data.data.data;
          this.setExpandedKeys(this.directoryData);
        } else {
          console.error("Failed to fetch data:", response.data.msg);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },

    setExpandedKeys(data) {
      const getKeys = (nodes) => {
        let keys = [];
        nodes.forEach(node => {
          keys.push(node.id);
          if (node.children && node.children.length) {
            keys = keys.concat(getKeys(node.children));
          }
        });
        return keys;
      };
      this.expandedKeys = getKeys(data);
    },

    addCategory() {
      this.newCategory = { label: '' };
      this.isCategoryCreated = false; // 禁用引入目录按钮
      this.showAddCategoryDialog = true;
    },

    async onSaveNewCategory() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_HOST}/api/documents/add`, {
          label: this.newCategory.label,
          parent_id: 0
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.code === 1) {
          this.newCategory.id = response.data.data.id; // 设置新大类的 ID
          this.isCategoryCreated = true; // 启用引入目录按钮
          ElMessage.success("大类创建成功，可选择引入现有目录或关闭");
        } else {
          ElMessage.error("创建大类失败: " + response.data.msg);
        }
      } catch (error) {
        ElMessage.error("创建大类失败: " + error.message);
      }
    },

    async importDefaultContents() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_HOST}/api/default_contents/importToDocuments`,
            {
              main_category_id: this.newCategory.id
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );

        if (response.data && response.data.code === 1) {
          ElMessage.success("默认目录已成功引入");
          this.showAddCategoryDialog = false; // 引入完成后关闭对话框
          this.fetchData();
        } else {
          ElMessage.error("引入默认目录失败: " + response.data.msg);
        }
      } catch (error) {
        ElMessage.error("引入默认目录失败: " + error.message);
      }
    },

    // 其他方法保持不变
    async deleteCategory(data) {
      try {
        await ElMessageBox.confirm('确定删除该大类及其所有内容吗?', '删除确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const token = localStorage.getItem('token');
        await axios.post(`${process.env.VUE_APP_HOST}/api/documents/delete`, {
          id: data.id,
          deleteAll: true // 用于服务器判断是否删除所有子内容
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        ElMessage.success('大类及其所有内容已删除');
        this.fetchData();
      } catch (error) {
        ElMessage.error("删除失败: " + (error.message || "未知错误"));
      }
    },

    goToDocumentDefault() {
      this.$router.push('/document-default');
    },

    // 手动控制展开和折叠
    toggleNode(node) {
      if (node.expanded) {
        this.$refs.directoryTree.store.collapseNode(node); // 折叠节点
      } else {
        this.$refs.directoryTree.store.expandNode(node); // 展开节点
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.add-category-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.add-category-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

/*create-default-button*/
.create-default-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.create-default-button:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}
.action-buttons .el-button{
  background: none!important;
  background: transparent!important;
  border-color: transparent!important;
  color: var(--el-color-primary)!important;
  padding-left: 0!important;
  padding-right: 0!important;
}
.data-center-option {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  background-color: var(--background-color);
}

.button-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.directory-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.directory-tree {
  border: 1px solid var(--lightgray-color);
  border-radius: 4px;
}

.el-tree-node__content {
  height: 50px !important;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.el-tree-node__content:hover {
  background-color: #f5f7fa;
}

.tree-node {
  display: flex;
  align-items: center;
  width: 100%;
}

.expand-icon {
  cursor: pointer;
  margin-right: 5px;
}

/* 一级分类的样式 */
.root-node .node-label {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.node-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
}

.leader-dots {
  flex-grow: 1;
  border-bottom: 1px dotted #333;
  margin: 0 10px;
  height: 0;
}

.action-buttons {
  font-size: 12px !important;
  padding: 5px !important;
}

.el-dialog__footer {
  text-align: right;
}

.el-form-item__label {
  font-weight: 500;
}

.el-tree-node__children {
  margin-left: 20px;
  border-left: 1px dashed var(--lightgray-color);
}
</style>
